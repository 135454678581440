/* ------------------------------------------------------------------------- */


/* HEADER STYLES */


/* ------------------------------------------------------------------------- */

.header { padding: 10px 0px;position: fixed; top: 0;right: 0;left: 0;z-index: 15; box-shadow: rgb(149 157 165 / 20%) 0px 3px 10px;}
.menu_logo svg { width: 100px!important;}
.main_header{padding: 0 40px;}
.menu_title{ color: $color; font-weight: $font-weight-bold; font-family: $main; margin: 30px;border-left: 1px solid $color-bg;padding: 30px; font-size: 20px; }
.menu_logout{ color: $color; font-weight: $font-weight-bold; font-family: $main;padding: 14px; display: flex; margin-top: 5px;}

.nav-link{ display: flex; justify-content: space-between; color: $color-txt!important; font-weight: $font-weight-normal!important;}
.nav-link-child{font-weight: $font-weight-normal!important; padding-left: 20px!important; font-size: 14px; background-color:#ebf3f0}
.nav-link-child:hover{ background-color: #ccd8d5!important; font-weight: $font-weight-normal!important; color: $color-txt!important;}
.nav-link svg { margin-top: 5px!important;}
.nav-link-active svg g{ fill: white }
.nav-link[aria-expanded="true"]{background-color: $color; color:white!important;}
.nav-link[aria-expanded="true"] svg g{fill: white}
.nav-left-sidebar {position: fixed;width: 170px;top: 76px;background-color: #fff;font-family: $main; overflow-y: scroll;height: 100%;}
.nav-left-sidebar .navbar {position: relative;padding: 30px 0px 120px 0px;}
.navbar-collapse {flex-basis: 100%;flex-grow: 1;align-items: center;}
.navbar-nav {display: flex;flex-direction: column;padding-left: 0;margin-bottom: 0;list-style: none;}
.navbar-nav {display: block;margin-right: 30px;}
.nav-left-sidebar .navbar-nav {width: 100%;}
.nav-left-sidebar .navbar-nav .nav-link {font-size: 14px;padding: 8px 15px;color: #71789e;transition: 0.3s;}
.nav-left-sidebar .navbar-nav .nav-link {color: $color-txt;}
.nav-left-sidebar .navbar-nav .nav-link-active {color: white!important;background: $color;}
.nav-title{margin: 0;font-weight: $font-weight-bold;padding: 8px 30px 5px 15px;color: $color-txt!important; font-size: 15px;margin-top: 20px; font-family: $second; text-transform: uppercase}
hr{border: none;margin: 5px 28px 10px 15px;width: 50px;height: 1px;background: $color;}
.align-hr{ margin-left: 0}
.responsive_menu{position: fixed;width: 100%;background-color: #fff;overflow-y: scroll;height: 100%;padding-bottom: 75px;}
.nav-link-active_responsive{color: white !important; background: $color;}
.nav-link-active_responsive:hover{ color: white!important;}

.header .header_idiomas {border-left: 1px solid $color;margin-left: 10px;padding-left: 10px;margin-top: 5px;}
.header .header_idiomas ul {display: flex;flex-direction: column;}
.header .header_idiomas ul li {display: inline-block;margin: 0 5px;}
.header .header_idiomas ul li a {color: #000;display: block;font-size: 12px;line-height: 120%;overflow: hidden;padding: 8px 0 5px;position: relative;text-decoration: none;text-transform: uppercase;}
.header_idiomas_active{color: $color!important;}


/*Breadcrumbs*/
.breadcrumbs{margin: 20px 20px 0px 220px; background: $color-bg; padding: 0;}
.breadcrumb { background-color: transparent; padding: 0; margin-bottom: 15px;display: flex;flex-wrap: wrap;}
.breadcrumb-item+.breadcrumb-item::before {content: "|"!important; color: $color;  display: inline-block;padding-right: 0.5rem;padding-left: 0.5rem;}
.breadcrumb-link{ font-weight: $font-weight-normal!important; font-family: $second;  font-size: 13px}
.breadcrumb-item{ font-family: $second; font-size: 13px; color: $color-txt!important;}
.breadcrumb-item.active{font-weight: $font-weight-bold!important;}
.breadcrumb>li {display: inline-block;}


.ease-in-out {transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);}
.duration-150 {transition-duration: 150ms;}
.transition {transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);transition-duration: 150ms;}

.text-gray-400 {--tw-text-opacity: 1;color: rgba(156, 163, 175, var(--tw-text-opacity));}
.p-2 {padding: 0.5rem;}
.rounded-md {border-radius: 0.375rem;}
.justify-center {justify-content: center;}
.items-center {align-items: center;}
.inline-flex {display: inline-flex;}
.p-2 {padding: 0.5rem!important;}
.w-6 {width: 1.5rem;}
.h-6 {height: 1.5rem;}
img, svg, video, canvas, audio, iframe, embed, object {display: block;vertical-align: middle;}
#toggle-hamburger--close{padding-top: 10px;}
#toggle-hamburger--open{padding-top: 10px;}


@media (min-width: 800px) {
    .toggle {display: block !important;}
    #toggle-hamburger--close {display: none}
    #toggle-hamburger--open {display: none}
}

@media (max-width: 800px) {
    #toggle-hamburger--close {display: block}
    #toggle-hamburger--open {display: block}
    #user-info{display: none;}
}
