/* ------------------------------------------------------------------------- */


/* PAGE PAGE STYLES */


/* ------------------------------------------------------------------------- */

main {padding-top: 90px;}
.main_content{ position: relative;left: 0;min-height: 870px !important;padding: 20px 40px 40px 30px;background: transparent;margin: 0px 20px 0px 190px;}
h2{ margin-bottom: 30px!important; font-weight: $font-weight-bold; font-size: 20px }
.message-hidden{display: none;}
#error_msg{ margin: 0}
.section{padding: 60px 0; border-top: 1px solid white;}


/*LOGIN*/
.header_logo{ width: 150px }


/*ARTISTS*/
.artist-section{display: flex;}
.artist-main_section{flex-basis: 70%; padding: 0px 30px 0px 0px;}
.artist-side_section{flex-basis: 30%;}
.artist-text_content{}

.tab {overflow: hidden;border-bottom: 1px solid $color;}
.tab button {background-color: inherit;float: left;border: none;outline: none;cursor: pointer;padding: 14px 16px;transition: 0.3s;}
.tab button:hover {background-color: #ddd; border-radius: 10px 10px 0px 0px;}
.tab button.active {border-bottom: 1px solid $color; background-color: white; border-radius: 10px 10px 0px 0px;}
.tabcontent {display: none; border-top: none;margin-top: 15px;padding: 0;}
.image_preview{width: 100px; padding: 5px}
.section_image_preview{margin-bottom: 15px;}
.member-item hr{ width: 100%; margin: 0;}
.member-item{padding: 13px 14px;background-color: $color-bg;margin-top: 0!important;margin-bottom: 10px;}
.member-item-arrow{height: 52px!important; padding: 17px;  background: transparent url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat!important;position: absolute; right: 0;top: -5px;}
#otherGenre hr{ width: 100%; margin: 20px 0px}
.moneybag{ background: white;width: 30%;padding: 20px 20px 10px 10px;text-align: center;border-radius: 10px;margin-bottom: 20px;}
.moneybag p{font-size: 16px;}
.moneybag span{color: $color; font-weight: $font-weight-bold}
.preview-section{display: flex;margin-bottom: 10px;}
#section_image_preview{display: flex;margin-bottom: 10px}


/*RELEASES*/
img.post_thumbnail{height: 200px; object-fit: contain;margin: auto!important;width: 100%; }
.thumbnail_content {background-color: white;border-radius: 5px;position: relative;}
.thumbnail_button{position: absolute;top: 50%;transform: translateY(-50%);width: 100%;text-align: center;cursor: pointer;}
.thumbnail_button span{ color: black;background-color: rgb(255 255 255 / 75%);padding: 15px 20px;border-radius: 6px;}
.open_media{ display: block; max-width: 100%; }

.song-section{display: flex; flex-direction: column;}
.song-first_section{display: flex;}
.song-main_section{flex-basis: 50%; padding: 0px 30px 0px 0px;}
.song-side_section{flex-basis: 50%; padding-top: 47px}
.song-side_section hr{width: 100%; margin:0}
.song-main_section h3{font-size: 20px;font-weight: $font-weight-bold;}
.artist_right-columns input{ flex-basis: 60%}
.artist_right-columns{ justify-content: space-between!important;}
.artist_right-columns p{ margin-top: 15px}
.large_hr{width: 100%; margin:0}
.artist_right-percentage{display: flex; gap:10px; flex-basis: 53%; justify-content: flex-start;}
.status{font-weight: $font-weight-bold; margin-top: -20px; margin-bottom: 30px;}
.name-status{font-weight: $font-weight-normal}


/*TRACKS*/
.track_master{border: 1px solid $color;padding: 5px 10px;border-radius: 10px;}
.master_column p{ color: $color; font-size: 12px; cursor: pointer;}
.master_column{align-items: baseline!important;justify-content: start!important;gap: 10px;}
.track_royalties{display: flex; flex-flow: column;}
.track_royalties__section{display: flex;flex-direction: row;gap: 100px;}
.track_royalties__artist{flex-basis: 25%;}
.track_royalties__member{flex-basis: 40%;}
.track_royalties__member_data{display: flex; flex-flow: row;justify-content: space-between !important;}
.track_royalties__member_data p{margin-top: 15px;}
.artist_right-percentage p {margin-top: 15px;}
.search_row .select2-container{width: 54%!important;}
.search_row .select2-container--default .select2-selection--single{ border:none!important; height: 38px !important;}
.search_row .select2-container .select2-selection--single .select2-selection__rendered{padding-top: 1px !important;}
.search_row{ margin-left: 3px}
.track_royalties hr{ width: 100%;margin: 0;}
.add_other_right{color: $color; margin-top: 20px; cursor: pointer}
.other_right{ padding: 20px; border: 1px solid $color;border-radius: 10px;}


/*DATASETS*/
.dataset-main_section{flex-basis: 30%; padding: 0px 30px 0px 0px;}
.dataset-section{display: flex}
.dataset-input{ display: flex}
.dataset-input .submit_button {height: 52px; padding: 15px 20px !important;}


/*DATATABLE*/
.dataTable{ font-size: 14px}
.dataTables_length label{ display: flex!important; margin-top: 10px!important;margin-bottom: 20px!important;}
.dataTables_wrapper .dataTables_filter input { width: 90%;padding: 15px!important;border-radius: 5px!important;height: 35px!important;color: #000000!important;font-size: 14px!important;background: $color-input!important;outline: 0!important;border: none !important;}
.dataTables_length label select{width: 80px!important;margin: -4px 10px 0px 10px!important;}
.dataTables_wrapper .dataTables_filter input{background-color: #fff;border-color: #6b7280;border-width: 1px;border-radius: 0px;padding-top: 0.5rem;padding-right: 0.75rem;padding-bottom: 0.5rem;padding-left: 0.75rem;}
table.dataTable.no-footer{ border-bottom: none!important;}
.dataTables_wrapper .dataTables_length select{ background: $color-input; padding: 0px 15px!important;border-radius: 5px!important;height: 35px!important;color: #000000!important;font-size: 14px!important;border: none !important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {cursor: default;color: $color-txt !important;border: 1px solid transparent;background: transparent;box-shadow: none;border-radius: 5px !important;margin: 0px 10px;}
.dataTables_wrapper .dataTables_paginate .paginate_button {background: white;border-radius: 5px;}
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover{color: white!important;}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:hover{ color: white!important; background: $color !important; border:none!important; border-radius: 5px!important;}
.row{margin-top: 30px}
table.dataTable tbody th, table.dataTable tbody td { padding: 15px 10px!important;}
.dataTables_wrapper {margin-bottom: 30px!important;}
.dataTables_paginate{ margin-top: 20px!important;}
.table_logo{width: 50px;height: 50px;object-fit: cover;outline: 6px solid #f5f5f5;}
.dataTables_wrapper .dataTables_filter{ width: 60%; float: left; margin-top: 7px; text-align: left;}
#sent-table_filter{ display: none}
.dataTables_filter label{ width: 100%}
.dataTables_wrapper .dataTables_length { float: right};
.table thead{ background-color: #abd5d2};
table.dataTable thead .sorting {background-image: url(../images/sort_both.png)!important;}
table.dataTable thead .sorting_desc {background-image: url(../images/sort_desc.png)!important;}
table.dataTable thead .sorting_asc {background-image: url(../images/sort_asc.png)!important;}
thead th:first-child {border-left: none;border-top-left-radius: 5px;}
thead th:last-child {border-left: none;border-top-right-radius: 5px;}
td a:hover{ color: $color}
.delete_btn{ color: #d90a19!important;}
.dataTables_wrapper.no-footer .dataTables_scrollBody {border-bottom: none;}
table.dataTable.display tbody tr > .sorting_1, table.dataTable.order-column.stripe tbody tr > .sorting_1 {background-color: #ffffff!important;}
table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {background-color: #D7D7D7!important;}
table.dataTable tbody tr {background-color: #ffffff!important;}
.table-hover tbody tr:hover {background-color: #D7D7D7!important;}
.table_link{ color: #006740}
.dataTables_wrapper .dataTables_processing{ top: 60%; height: 80px; padding-top: 30px;}
.toggle_btn{float: right;background-color: white;padding: 5px;border-radius: 8px;margin-top: 3px;margin-left: 100px;}
.toggle_btn img {width: 25px;}
.toggle_collapse{ position: absolute;z-index: 1;background-color: white;padding: 10px;right: 0;width: 160px;top: 34px;}
.toggle_content_collapse{ display: flex;flex-direction: column;}
.active{color: $color!important; font-weight: $font-weight-bold}


/*MEDIA POPUP*/
.media-section-background{display: none;left: 0;top: 0;width: 100%;height: 100%;position: fixed;z-index: 1000;background-color: rgba(0, 0, 0, 0.7);}
.media-section-container {position: relative;width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;}
.media-close-section {position: absolute;width: 100%;height: 100%;left: 0;top: 0;z-index: 1;}
.media-info-container {background-color: #fff;margin: auto;width: 90%;max-height: 100%;max-width: 1000px;z-index: 1;overflow: auto;}
.media-header {padding: 30px 0;}
.media-header p {text-align: left;margin: auto 0;margin-left: 30px;font-weight: $font-weight-bold;line-height: 120%;font-size: 24px;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {color: $color!important;}
.tab-regular .tab-content {background-color: #fff;padding: 30px;border: 1px solid #e6e6f2;border-radius: 4px;border-top-left-radius: 0px;}
.modal-scroll {overflow-y: scroll;height: 50vh;text-align: left!important;}
.media-img {height: 150px;margin-top: 5px;object-fit: cover;display: inline;width: 150px;}
.img-active {border: 4px solid $color;}
.dropzone{ border: none!important;}
.modal-body{min-height: 300px!important;}
#uploadMedia .modal-body{ text-align: center;}


/*IMPORTS*/
.dz-message {text-align: center;padding: 1.5rem;display: block;font-size: .875rem;font-weight: 500;opacity: 1;cursor: pointer;border-radius: 0.5rem;background: #fff;
  border: 1px dashed #E9EEF2;transition: all .35s ease;}
.dropzone {min-height: 150px;border: 1px solid $color!important;background: white;padding: 20px 20px;}
.dropzone-title{ margin-bottom: 20px; }
.excel-loader{width: 30px;margin: auto;margin-top: 30px;}
.dropzone-no-borders{ border: none!important;}
.hundreds__loader svg{ margin: auto}


/*ROYALTIES*/
.royalty-box { background:white; border-radius:10px; display:flex; }
.royalty-box .royalty-box__column { flex-basis: 33.3%; border-right:1px solid #F0F0F0; padding:20px 12px; }
.royalty-box .royalty-box__column:last-child { border:none; }
.royalty-box .royalty-box__column .royalty-block { border-radius:8px; padding:12px; margin-top: 10px}
.royalty-box .royalty-box__column .royalty-block.highlighted {background:#34b9b147;}
.royalty-box .royalty-box__column .royalty-block.highlighted-change {background:#34b9b147;}
.royalty-box .royalty-box__column .royalty-block h2 { font-size:16px; margin-bottom:7px!important; }
.royalty-box .royalty-box__column .royalty-block p { margin:0; font-size:14px; }
.royalty-block_space{ height: 70px}


/*CHARTS*/
.highcharts-figure, .highcharts-data-table table {min-width: 320px;max-width: 700px;margin: 1em auto;}
.highcharts-data-table table {border-collapse: collapse;border: 1px solid #ebebeb;margin: 10px auto;text-align: center;width: 100%;max-width: 500px;}
.highcharts-data-table caption {padding: 1em 0;font-size: 1.2em;color: #555;}
.highcharts-data-table th {font-weight: 600;padding: 0.5em;}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {padding: 0.5em;}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {background: #f8f8f8;}
.highcharts-data-table tr:hover {background: #f1f7ff;}
.highcharts-exporting-group{display: none}


/*INCOME*/
.resume{width: 50%;margin-top: 20px;display: flex}
.resume_section{ flex: 1;}
.resume_section p { margin-left: 20px;}
.resume_title{ font-weight: $font-weight-bold; margin-left: 0!important; }
.resume_final{ font-weight: $font-weight-bold; color: $color; margin-left: 0!important; padding-top: 10px;}
.resume_val{ font-weight: $font-weight-bold}
.resume_val_final{ font-weight: $font-weight-bold; color: $color; padding-top: 10px;}
.track_info_table {display: table;width: 80%;border-collapse: collapse;}
.track_info_row {display: table-row;}
.track_info_cell {display: table-cell;padding: 8px;border: 1px solid $color;background-color: #fff;text-align: center;padding: 15px 0px;}
.track_info_header {font-weight: $font-weight-bold;}
#resume_section_title{flex-basis: 70%!important;}
#resume_section_content{flex-basis: 30%!important;}
.search-section{display: flex}
.two-rows{display: flex; flex-direction: column;}
.two-rows .two-columns{padding-bottom: 10px}
.two-rows a{margin: 0 !important; display: flow;}
#royalty-loader{width: 100%;text-align: center;margin-top: 20px;}
#royalty-error{width: 100%;text-align: center;margin-top: 20px;}


@media only screen and (max-width: 1300px) {
    .dataset-main_section {flex-basis: 45%;padding: 0;}
    .resume{width: 60%;}
    .track_royalties__artist{flex-basis: 40%;}
}

@media only screen and (max-width: 1200px) {
    .artist-section {display: block;}
    .artist-main_section{padding: 0;}
    .dataset-main_section{flex-basis: 40%; padding: 0;}
    .song-first_section {display: block;}
    .song-side_section{padding: 0;}
}

@media only screen and (max-width: 1024px) {
    .main_content {margin-left: 30px;}
    .nav-left-sidebar{ display: none}
    .breadcrumbs {margin: 20px 20px 0px 60px;}
    .track_royalties__section {flex-direction: column;gap: 25px;}
    .artist_right-percentage {flex-basis: 60%;}

}
