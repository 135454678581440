/* ------------------------------------------------------------------------- */
/* VARIABLES 100X100 */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE FRO 100X100 */
/* ------------------------------------------------------------------------- */
@import "library/dropzone/basic.min.css";
@import "library/dropzone/dropzone.min.css";
.center-content {
  text-align: center;
}

.left-content {
  text-align: left;
}

.right-content {
  text-align: right;
}

/* ------------------------------------------------------------------------- */
/* 100X100 GRID SYSTEM */
/* ------------------------------------------------------------------------- */
.column-container {
  font-size: 0;
}

.column {
  display: inline-block;
  padding: 0.5%;
  vertical-align: top;
}

.column-1 {
  width: 8.3333333333%;
}

.column-2 {
  width: 16.6666666667%;
}

.column-3 {
  width: 25%;
}

.column-4 {
  width: 33.333333333%;
}

.column-5 {
  width: 41.666666667%;
}

.column-6 {
  width: 50%;
}

.column-7 {
  width: 58.333333333%;
}

.column-8 {
  width: 66.666666667%;
}

.column-9 {
  width: 75%;
}

.column-10 {
  width: 83.33333333%;
}

.column-11 {
  width: 91.66666667%;
}

.column-12 {
  width: 100%;
}

/* Special Grids */
.column-margin {
  display: inline-block;
  vertical-align: top;
}

.column-1_11 {
  width: 8.5%;
  margin-left: 0.29545455%;
  margin-right: 0.29545455%;
}

@media only screen and (max-width: 1100px) {
  .column-1-tablet {
    width: 8.3333333333%;
  }
  .column-2-tablet {
    width: 16.6666666667%;
  }
  .column-3-tablet {
    width: 25%;
  }
  .column-4-tablet {
    width: 33.333333333%;
  }
  .column-5-tablet {
    width: 41.666666667%;
  }
  .column-6-tablet {
    width: 50%;
  }
  .column-7-tablet {
    width: 58.333333333%;
  }
  .column-8-tablet {
    width: 66.666666667%;
  }
  .column-9-tablet {
    width: 75%;
  }
  .column-10-tablet {
    width: 83.33333333%;
  }
  .column-11-tablet {
    width: 91.66666667%;
  }
  .column-12-tablet {
    width: 100%;
  }
}
@media only screen and (max-width: 800px) {
  .column-1-mobile {
    width: 8.3333333333%;
  }
  .column-2-mobile {
    width: 16.6666666667%;
  }
  .column-3-mobile {
    width: 25%;
  }
  .column-4-mobile {
    width: 33.333333333%;
  }
  .column-5-mobile {
    width: 41.666666667%;
  }
  .column-6-mobile {
    width: 50%;
  }
  .column-7-mobile {
    width: 58.333333333%;
  }
  .column-8-mobile {
    width: 66.666666667%;
  }
  .column-9-mobile {
    width: 75%;
  }
  .column-10-mobile {
    width: 83.33333333%;
  }
  .column-11-mobile {
    width: 91.66666667%;
  }
  .column-12-mobile {
    width: 100%;
  }
  .column-0-mobile {
    display: none;
  }
}
/* ------------------------------------------------------------------------- */
/* VARIABLES FORM VALIDATION 100x100 */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE FORM VALIDATION 100x100 */
/* ------------------------------------------------------------------------- */
/* INPUT CSS */
.hundreds-validation__input_error {
  border-color: #d63031 !important;
}

label.hundreds-validation__input_error,
p.hundreds-validation__input_error,
label.hundreds-validation__input_error a {
  color: #d63031 !important;
  border-color: #d63031 !important;
}

/*.hundreds-validation__input_success{
	border-color: $hundreds-success-color !important;
}*/
/* MESSAGES CSS */
.form-messages {
  margin: 20px 0 30px;
}

.form-messages__success {
  color: #00b894;
}

.form-messages__errors {
  color: #d63031;
}

.form-messages_hide {
  display: none;
}

.form-messages_show {
  display: block;
}

/* ------------------------------------------------------------------------- */
/* FONTS */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* VARIABLES */
/* ------------------------------------------------------------------------- */
/* 400 y 600*/
/* 400 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}

/* ==========================================================================
   RESET CSS
   ========================================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}

dl, ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}

a {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

p a {
  outline: 0;
}

p a:hover {
  text-decoration: underline;
}

input[type=submit],
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html,
body {
  overflow-x: hidden;
}

/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE - Classes used throught all the project */
/* ------------------------------------------------------------------------- */
body {
  background-color: white;
  min-height: 100%;
  font-family: "Poppins", sans-serif !important;
  color: #2B2B34;
  font-size: 15px;
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
}

p {
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 10px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-medio {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-mini {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-micro {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

img {
  max-width: 100%;
  height: auto;
}

a,
button {
  cursor: pointer;
}

.clearfix, .clear {
  clear: both;
}

strong {
  font-weight: 600;
}

p a, a {
  text-decoration: none;
  color: #2B2B34;
  font-weight: 400;
}

p i, i {
  font-style: italic;
}

ul {
  list-style: none;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

hr {
  border: none;
  margin: 25px 0;
  width: 50px;
  height: 1px;
  background: #06b8ad;
}

hr.center {
  margin: 25px auto;
}

.no_padding {
  padding: 0px !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.padding-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-big {
  padding-top: 140px;
  padding-bottom: 140px;
}

.padding-anchor {
  padding-top: 100px;
  padding-bottom: 40px;
}

.left {
  float: left;
}

.right {
  float: right;
}

/* CALLTOACTIONS */
a.calltoaction {
  display: inline-block;
  margin: 0;
  width: auto;
  text-align: center;
  padding: 10px 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 120%;
  border-radius: 5px;
  font-size: 14px;
  background-color: #06b8ad;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 1;
  -webkit-appearance: none;
}

a.calltoaction:hover {
  text-decoration: none;
  background-color: #068052;
}

a.calltoaction.white {
  background: white;
  color: #2B2B34;
}

a.calltoaction.white:hover {
  text-decoration: none;
}

/* ANIMATIONS */
.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -ms-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.fadeToLeft {
  -webkit-animation-name: fadeToLeft;
  -moz-animation-name: fadeToLeft;
  -o-animation-name: fadeToLeft;
  animation-name: fadeToLeft;
}

.fadeToRight {
  -webkit-animation-name: fadeToRight;
  -moz-animation-name: fadeToRight;
  -o-animation-name: fadeToRight;
  animation-name: fadeToRight;
}

.fadeOpacity {
  -webkit-animation-name: fadeOpacity;
  -moz-animation-name: fadeOpacity;
  -o-animation-name: fadeOpacity;
  animation-name: fadeOpacity;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes blinkingimage {
  50% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes blinking {
  50% {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 200px);
    transform: translate(0, 200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(10px, 0);
    transform: translate(100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeToRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(-10px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@media only screen and (max-width: 1200px) {
  body, p, ul li {
    font-size: 14px;
  }
}
@media only screen and (max-width: 800px) {
  .padding-small {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .padding-medium {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .padding-big {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .padding-anchor {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  .columna2, .columna2-last {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .columna4 {
    width: 49%;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 600px) {
  p, ul li {
    font-size: 13px;
  }
  .padding-small {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .padding-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .padding-big {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .padding-anchor {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE - Classes used throught all the project */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* COOKIES */
/* ------------------------------------------------------------------------- */
.cookies {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 90%;
  max-width: 400px;
  padding: 25px 40px 20px 25px;
  z-index: 100;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.cookies .message-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background: transparent url("../../images/close.svg") no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.cookies p {
  font-size: 14px;
  color: #2B2B34;
  line-height: 130%;
  margin-bottom: 0;
}

.cookies p.cookies__text {
  margin-bottom: 3px;
}

.cookies p:last-child {
  text-align: right;
}

.cookies p a {
  color: #2B2B34;
  font-weight: 600;
  font-size: 15px;
}

.cookies p a:hover {
  color: #2B2B34;
}

@media only screen and (max-width: 800px) {
  .cookies__text {
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px) {
  .cookies {
    padding: 30px 50px 30px 20px;
  }
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: #06b8ad;
}

/* ------------------------------------------------------------------------- */
/* HEADER STYLES */
/* ------------------------------------------------------------------------- */
.header {
  padding: 10px 0px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 15;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 10px;
}

.menu_logo svg {
  width: 100px !important;
}

.main_header {
  padding: 0 40px;
}

.menu_title {
  color: #06b8ad;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 30px;
  border-left: 1px solid #f0f0f0;
  padding: 30px;
  font-size: 20px;
}

.menu_logout {
  color: #06b8ad;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  padding: 14px;
  display: flex;
  margin-top: 5px;
}

.nav-link {
  display: flex;
  justify-content: space-between;
  color: #2B2B34 !important;
  font-weight: 400 !important;
}

.nav-link-child {
  font-weight: 400 !important;
  padding-left: 20px !important;
  font-size: 14px;
  background-color: #ebf3f0;
}

.nav-link-child:hover {
  background-color: #ccd8d5 !important;
  font-weight: 400 !important;
  color: #2B2B34 !important;
}

.nav-link svg {
  margin-top: 5px !important;
}

.nav-link-active svg g {
  fill: white;
}

.nav-link[aria-expanded=true] {
  background-color: #06b8ad;
  color: white !important;
}

.nav-link[aria-expanded=true] svg g {
  fill: white;
}

.nav-left-sidebar {
  position: fixed;
  width: 170px;
  top: 76px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  overflow-y: scroll;
  height: 100%;
}

.nav-left-sidebar .navbar {
  position: relative;
  padding: 30px 0px 120px 0px;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav {
  display: block;
  margin-right: 30px;
}

.nav-left-sidebar .navbar-nav {
  width: 100%;
}

.nav-left-sidebar .navbar-nav .nav-link {
  font-size: 14px;
  padding: 8px 15px;
  color: #71789e;
  transition: 0.3s;
}

.nav-left-sidebar .navbar-nav .nav-link {
  color: #2B2B34;
}

.nav-left-sidebar .navbar-nav .nav-link-active {
  color: white !important;
  background: #06b8ad;
}

.nav-title {
  margin: 0;
  font-weight: 600;
  padding: 8px 30px 5px 15px;
  color: #2B2B34 !important;
  font-size: 15px;
  margin-top: 20px;
  font-family: "Lora", serif;
  text-transform: uppercase;
}

hr {
  border: none;
  margin: 5px 28px 10px 15px;
  width: 50px;
  height: 1px;
  background: #06b8ad;
}

.align-hr {
  margin-left: 0;
}

.responsive_menu {
  position: fixed;
  width: 100%;
  background-color: #fff;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 75px;
}

.nav-link-active_responsive {
  color: white !important;
  background: #06b8ad;
}

.nav-link-active_responsive:hover {
  color: white !important;
}

.header .header_idiomas {
  border-left: 1px solid #06b8ad;
  margin-left: 10px;
  padding-left: 10px;
  margin-top: 5px;
}

.header .header_idiomas ul {
  display: flex;
  flex-direction: column;
}

.header .header_idiomas ul li {
  display: inline-block;
  margin: 0 5px;
}

.header .header_idiomas ul li a {
  color: #000;
  display: block;
  font-size: 12px;
  line-height: 120%;
  overflow: hidden;
  padding: 8px 0 5px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
}

.header_idiomas_active {
  color: #06b8ad !important;
}

/*Breadcrumbs*/
.breadcrumbs {
  margin: 20px 20px 0px 220px;
  background: #f0f0f0;
  padding: 0;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "|" !important;
  color: #06b8ad;
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.breadcrumb-link {
  font-weight: 400 !important;
  font-family: "Lora", serif;
  font-size: 13px;
}

.breadcrumb-item {
  font-family: "Lora", serif;
  font-size: 13px;
  color: #2B2B34 !important;
}

.breadcrumb-item.active {
  font-weight: 600 !important;
}

.breadcrumb > li {
  display: inline-block;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-150 {
  transition-duration: 150ms;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.p-2 {
  padding: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.inline-flex {
  display: inline-flex;
}

.p-2 {
  padding: 0.5rem !important;
}

.w-6 {
  width: 1.5rem;
}

.h-6 {
  height: 1.5rem;
}

img, svg, video, canvas, audio, iframe, embed, object {
  display: block;
  vertical-align: middle;
}

#toggle-hamburger--close {
  padding-top: 10px;
}

#toggle-hamburger--open {
  padding-top: 10px;
}

@media (min-width: 800px) {
  .toggle {
    display: block !important;
  }
  #toggle-hamburger--close {
    display: none;
  }
  #toggle-hamburger--open {
    display: none;
  }
}
@media (max-width: 800px) {
  #toggle-hamburger--close {
    display: block;
  }
  #toggle-hamburger--open {
    display: block;
  }
  #user-info {
    display: none;
  }
}
/* ------------------------------------------------------------------------- */
/* BASE PAGE STYLES */
/* ------------------------------------------------------------------------- */
.items-center {
  align-items: center;
}

.bg-gray-100 {
  background-color: #f0f0f0 !important;
}

.flex-col {
  flex-direction: column;
}

.flex {
  display: flex;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.block {
  display: block;
}

.justify-end {
  justify-content: flex-end;
}

.rounded {
  border-radius: 0.25rem;
}

.min-h-screen {
  min-height: 100vh;
}

.justify-between {
  justify-content: space-between;
}

.h-16 {
  height: 4rem;
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-b {
  border-bottom-width: 1px;
}

.bg-white {
  background-color: #fff !important;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

.hidden {
  display: none;
}

.navbar {
  display: block;
}

a:hover {
  color: #06b8ad !important;
}

.divisor {
  color: #06b8ad;
  padding: 10px;
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}
@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
  .navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
  }
}
@media (min-width: 640px) {
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
  .sm\:max-w-md {
    max-width: 28rem;
  }
  .sm\:pt-0 {
    padding-top: 0px;
  }
  .sm\:justify-center {
    justify-content: center;
  }
}
/* ------------------------------------------------------------------------- */
/* PAGE PAGE STYLES */
/* ------------------------------------------------------------------------- */
main {
  padding-top: 90px;
}

.main_content {
  position: relative;
  left: 0;
  min-height: 870px !important;
  padding: 20px 40px 40px 30px;
  background: transparent;
  margin: 0px 20px 0px 190px;
}

h2 {
  margin-bottom: 30px !important;
  font-weight: 600;
  font-size: 20px;
}

.message-hidden {
  display: none;
}

#error_msg {
  margin: 0;
}

.section {
  padding: 60px 0;
  border-top: 1px solid white;
}

/*LOGIN*/
.header_logo {
  width: 150px;
}

/*ARTISTS*/
.artist-section {
  display: flex;
}

.artist-main_section {
  flex-basis: 70%;
  padding: 0px 30px 0px 0px;
}

.artist-side_section {
  flex-basis: 30%;
}

.tab {
  overflow: hidden;
  border-bottom: 1px solid #06b8ad;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tab button:hover {
  background-color: #ddd;
  border-radius: 10px 10px 0px 0px;
}

.tab button.active {
  border-bottom: 1px solid #06b8ad;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
}

.tabcontent {
  display: none;
  border-top: none;
  margin-top: 15px;
  padding: 0;
}

.image_preview {
  width: 100px;
  padding: 5px;
}

.section_image_preview {
  margin-bottom: 15px;
}

.member-item hr {
  width: 100%;
  margin: 0;
}

.member-item {
  padding: 13px 14px;
  background-color: #f0f0f0;
  margin-top: 0 !important;
  margin-bottom: 10px;
}

.member-item-arrow {
  height: 52px !important;
  padding: 17px;
  background: transparent url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat !important;
  position: absolute;
  right: 0;
  top: -5px;
}

#otherGenre hr {
  width: 100%;
  margin: 20px 0px;
}

.moneybag {
  background: white;
  width: 30%;
  padding: 20px 20px 10px 10px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.moneybag p {
  font-size: 16px;
}

.moneybag span {
  color: #06b8ad;
  font-weight: 600;
}

.preview-section {
  display: flex;
  margin-bottom: 10px;
}

#section_image_preview {
  display: flex;
  margin-bottom: 10px;
}

/*RELEASES*/
img.post_thumbnail {
  height: 200px;
  object-fit: contain;
  margin: auto !important;
  width: 100%;
}

.thumbnail_content {
  background-color: white;
  border-radius: 5px;
  position: relative;
}

.thumbnail_button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.thumbnail_button span {
  color: black;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 15px 20px;
  border-radius: 6px;
}

.open_media {
  display: block;
  max-width: 100%;
}

.song-section {
  display: flex;
  flex-direction: column;
}

.song-first_section {
  display: flex;
}

.song-main_section {
  flex-basis: 50%;
  padding: 0px 30px 0px 0px;
}

.song-side_section {
  flex-basis: 50%;
  padding-top: 47px;
}

.song-side_section hr {
  width: 100%;
  margin: 0;
}

.song-main_section h3 {
  font-size: 20px;
  font-weight: 600;
}

.artist_right-columns input {
  flex-basis: 60%;
}

.artist_right-columns {
  justify-content: space-between !important;
}

.artist_right-columns p {
  margin-top: 15px;
}

.large_hr {
  width: 100%;
  margin: 0;
}

.artist_right-percentage {
  display: flex;
  gap: 10px;
  flex-basis: 53%;
  justify-content: flex-start;
}

.status {
  font-weight: 600;
  margin-top: -20px;
  margin-bottom: 30px;
}

.name-status {
  font-weight: 400;
}

/*TRACKS*/
.track_master {
  border: 1px solid #06b8ad;
  padding: 5px 10px;
  border-radius: 10px;
}

.master_column p {
  color: #06b8ad;
  font-size: 12px;
  cursor: pointer;
}

.master_column {
  align-items: baseline !important;
  justify-content: start !important;
  gap: 10px;
}

.track_royalties {
  display: flex;
  flex-flow: column;
}

.track_royalties__section {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.track_royalties__artist {
  flex-basis: 25%;
}

.track_royalties__member {
  flex-basis: 40%;
}

.track_royalties__member_data {
  display: flex;
  flex-flow: row;
  justify-content: space-between !important;
}

.track_royalties__member_data p {
  margin-top: 15px;
}

.artist_right-percentage p {
  margin-top: 15px;
}

.search_row .select2-container {
  width: 54% !important;
}

.search_row .select2-container--default .select2-selection--single {
  border: none !important;
  height: 38px !important;
}

.search_row .select2-container .select2-selection--single .select2-selection__rendered {
  padding-top: 1px !important;
}

.search_row {
  margin-left: 3px;
}

.track_royalties hr {
  width: 100%;
  margin: 0;
}

.add_other_right {
  color: #06b8ad;
  margin-top: 20px;
  cursor: pointer;
}

.other_right {
  padding: 20px;
  border: 1px solid #06b8ad;
  border-radius: 10px;
}

/*DATASETS*/
.dataset-main_section {
  flex-basis: 30%;
  padding: 0px 30px 0px 0px;
}

.dataset-section {
  display: flex;
}

.dataset-input {
  display: flex;
}

.dataset-input .submit_button {
  height: 52px;
  padding: 15px 20px !important;
}

/*DATATABLE*/
.dataTable {
  font-size: 14px;
}

.dataTables_length label {
  display: flex !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}

.dataTables_wrapper .dataTables_filter input {
  width: 90%;
  padding: 15px !important;
  border-radius: 5px !important;
  height: 35px !important;
  color: #000000 !important;
  font-size: 14px !important;
  background: white !important;
  outline: 0 !important;
  border: none !important;
}

.dataTables_length label select {
  width: 80px !important;
  margin: -4px 10px 0px 10px !important;
}

.dataTables_wrapper .dataTables_filter input {
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
}

table.dataTable.no-footer {
  border-bottom: none !important;
}

.dataTables_wrapper .dataTables_length select {
  background: white;
  padding: 0px 15px !important;
  border-radius: 5px !important;
  height: 35px !important;
  color: #000000 !important;
  font-size: 14px !important;
  border: none !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #2B2B34 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
  border-radius: 5px !important;
  margin: 0px 10px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: white;
  border-radius: 5px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover {
  color: white !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  background: #06b8ad !important;
  border: none !important;
  border-radius: 5px !important;
}

.row {
  margin-top: 30px;
}

table.dataTable tbody th, table.dataTable tbody td {
  padding: 15px 10px !important;
}

.dataTables_wrapper {
  margin-bottom: 30px !important;
}

.dataTables_paginate {
  margin-top: 20px !important;
}

.table_logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  outline: 6px solid #f5f5f5;
}

.dataTables_wrapper .dataTables_filter {
  width: 60%;
  float: left;
  margin-top: 7px;
  text-align: left;
}

#sent-table_filter {
  display: none;
}

.dataTables_filter label {
  width: 100%;
}

.dataTables_wrapper .dataTables_length {
  float: right;
}

.table thead {
  background-color: #abd5d2;
}

table.dataTable thead .sorting {
  background-image: url(../images/sort_both.png) !important;
}

table.dataTable thead .sorting_desc {
  background-image: url(../images/sort_desc.png) !important;
}

table.dataTable thead .sorting_asc {
  background-image: url(../images/sort_asc.png) !important;
}

thead th:first-child {
  border-left: none;
  border-top-left-radius: 5px;
}

thead th:last-child {
  border-left: none;
  border-top-right-radius: 5px;
}

td a:hover {
  color: #06b8ad;
}

.delete_btn {
  color: #d90a19 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none;
}

table.dataTable.display tbody tr > .sorting_1, table.dataTable.order-column.stripe tbody tr > .sorting_1 {
  background-color: #ffffff !important;
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #D7D7D7 !important;
}

table.dataTable tbody tr {
  background-color: #ffffff !important;
}

.table-hover tbody tr:hover {
  background-color: #D7D7D7 !important;
}

.table_link {
  color: #006740;
}

.dataTables_wrapper .dataTables_processing {
  top: 60%;
  height: 80px;
  padding-top: 30px;
}

.toggle_btn {
  float: right;
  background-color: white;
  padding: 5px;
  border-radius: 8px;
  margin-top: 3px;
  margin-left: 100px;
}

.toggle_btn img {
  width: 25px;
}

.toggle_collapse {
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: 10px;
  right: 0;
  width: 160px;
  top: 34px;
}

.toggle_content_collapse {
  display: flex;
  flex-direction: column;
}

.active {
  color: #06b8ad !important;
  font-weight: 600;
}

/*MEDIA POPUP*/
.media-section-background {
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}

.media-section-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-close-section {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}

.media-info-container {
  background-color: #fff;
  margin: auto;
  width: 90%;
  max-height: 100%;
  max-width: 1000px;
  z-index: 1;
  overflow: auto;
}

.media-header {
  padding: 30px 0;
}

.media-header p {
  text-align: left;
  margin: auto 0;
  margin-left: 30px;
  font-weight: 600;
  line-height: 120%;
  font-size: 24px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #06b8ad !important;
}

.tab-regular .tab-content {
  background-color: #fff;
  padding: 30px;
  border: 1px solid #e6e6f2;
  border-radius: 4px;
  border-top-left-radius: 0px;
}

.modal-scroll {
  overflow-y: scroll;
  height: 50vh;
  text-align: left !important;
}

.media-img {
  height: 150px;
  margin-top: 5px;
  object-fit: cover;
  display: inline;
  width: 150px;
}

.img-active {
  border: 4px solid #06b8ad;
}

.dropzone {
  border: none !important;
}

.modal-body {
  min-height: 300px !important;
}

#uploadMedia .modal-body {
  text-align: center;
}

/*IMPORTS*/
.dz-message {
  text-align: center;
  padding: 1.5rem;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  opacity: 1;
  cursor: pointer;
  border-radius: 0.5rem;
  background: #fff;
  border: 1px dashed #E9EEF2;
  transition: all 0.35s ease;
}

.dropzone {
  min-height: 150px;
  border: 1px solid #06b8ad !important;
  background: white;
  padding: 20px 20px;
}

.dropzone-title {
  margin-bottom: 20px;
}

.excel-loader {
  width: 30px;
  margin: auto;
  margin-top: 30px;
}

.dropzone-no-borders {
  border: none !important;
}

.hundreds__loader svg {
  margin: auto;
}

/*ROYALTIES*/
.royalty-box {
  background: white;
  border-radius: 10px;
  display: flex;
}

.royalty-box .royalty-box__column {
  flex-basis: 33.3%;
  border-right: 1px solid #F0F0F0;
  padding: 20px 12px;
}

.royalty-box .royalty-box__column:last-child {
  border: none;
}

.royalty-box .royalty-box__column .royalty-block {
  border-radius: 8px;
  padding: 12px;
  margin-top: 10px;
}

.royalty-box .royalty-box__column .royalty-block.highlighted {
  background: rgba(52, 185, 177, 0.2784313725);
}

.royalty-box .royalty-box__column .royalty-block.highlighted-change {
  background: rgba(52, 185, 177, 0.2784313725);
}

.royalty-box .royalty-box__column .royalty-block h2 {
  font-size: 16px;
  margin-bottom: 7px !important;
}

.royalty-box .royalty-box__column .royalty-block p {
  margin: 0;
  font-size: 14px;
}

.royalty-block_space {
  height: 70px;
}

/*CHARTS*/
.highcharts-figure, .highcharts-data-table table {
  min-width: 320px;
  max-width: 700px;
  margin: 1em auto;
}

.highcharts-data-table table {
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-exporting-group {
  display: none;
}

/*INCOME*/
.resume {
  width: 50%;
  margin-top: 20px;
  display: flex;
}

.resume_section {
  flex: 1;
}

.resume_section p {
  margin-left: 20px;
}

.resume_title {
  font-weight: 600;
  margin-left: 0 !important;
}

.resume_final {
  font-weight: 600;
  color: #06b8ad;
  margin-left: 0 !important;
  padding-top: 10px;
}

.resume_val {
  font-weight: 600;
}

.resume_val_final {
  font-weight: 600;
  color: #06b8ad;
  padding-top: 10px;
}

.track_info_table {
  display: table;
  width: 80%;
  border-collapse: collapse;
}

.track_info_row {
  display: table-row;
}

.track_info_cell {
  display: table-cell;
  padding: 8px;
  border: 1px solid #06b8ad;
  background-color: #fff;
  text-align: center;
  padding: 15px 0px;
}

.track_info_header {
  font-weight: 600;
}

#resume_section_title {
  flex-basis: 70% !important;
}

#resume_section_content {
  flex-basis: 30% !important;
}

.search-section {
  display: flex;
}

.two-rows {
  display: flex;
  flex-direction: column;
}

.two-rows .two-columns {
  padding-bottom: 10px;
}

.two-rows a {
  margin: 0 !important;
  display: flow;
}

#royalty-loader {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

#royalty-error {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1300px) {
  .dataset-main_section {
    flex-basis: 45%;
    padding: 0;
  }
  .resume {
    width: 60%;
  }
  .track_royalties__artist {
    flex-basis: 40%;
  }
}
@media only screen and (max-width: 1200px) {
  .artist-section {
    display: block;
  }
  .artist-main_section {
    padding: 0;
  }
  .dataset-main_section {
    flex-basis: 40%;
    padding: 0;
  }
  .song-first_section {
    display: block;
  }
  .song-side_section {
    padding: 0;
  }
}
@media only screen and (max-width: 1024px) {
  .main_content {
    margin-left: 30px;
  }
  .nav-left-sidebar {
    display: none;
  }
  .breadcrumbs {
    margin: 20px 20px 0px 60px;
  }
  .track_royalties__section {
    flex-direction: column;
    gap: 25px;
  }
  .artist_right-percentage {
    flex-basis: 60%;
  }
}
/* ------------------------------------------------------------------------- */
/* GENERAL FORM STYLES */
/* ------------------------------------------------------------------------- */
form label {
  width: 100%;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
  display: inline-block !important;
  font-size: 16px !important;
  line-height: 120% !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

form input:focus {
  border: 1px solid #999999 !important;
  --tw-ring-color: $color-dark_grey!important;
}

form input[type=checkbox] {
  border: 1px solid #06b8ad;
}

[type=checkbox], [type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
}

.button_big {
  text-align: center;
  color: white;
  width: auto !important;
  padding: 8px 15px !important;
  margin-left: 10px !important;
  font-weight: 400;
}

.button_big:hover {
  color: white;
  text-decoration: none;
}

.submit_button {
  display: inline-flex;
  font-family: "Poppins", sans-serif;
  color: white !important;
  background: #06b8ad;
  font-size: 14px;
  font-weight: 400 !important;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 10px 20px;
  width: 170px;
  -webkit-appearance: none;
  transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

.submit_button:hover {
  background: #1ca198;
  color: white !important;
  font-weight: 400 !important;
}

.download_button {
  margin-left: 7px;
  transition: all 0.2s;
}

.submit_button:hover .download_button {
  transform: translateY(5px);
}

.preview_button {
  background: #272C51;
}

.preview_button:hover {
  background: #1a526d;
}

.grey-button {
  background: transparent;
  color: #06b8ad !important;
  font-weight: 400 !important;
  border: 1px solid #06b8ad;
}

.grey-button:hover {
  background: transparent !important;
  font-weight: 400 !important;
  color: #1ca198 !important;
  border: 1px solid #1ca198;
}

.delete_option {
  padding: 15px 5px;
  color: #06b8ad !important;
  font-size: 12px;
}

.delete_cross {
  color: red;
}

.delete-button {
  background: none;
  color: red !important;
}

.delete-button:hover {
  background: none;
  color: rgba(150, 0, 0, 0.85) !important;
}

.flex-column {
  flex-direction: column;
}

.password_text {
  margin-bottom: 20px;
}

.form_label {
  color: #2B2B34;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

[type=checkbox]:checked {
  background-image: none !important;
  background-color: #06b8ad !important;
  border: 1px solid #06b8ad;
}

.big-input {
  font-size: 20px !important;
}

.form-control:focus {
  background-color: white !important;
  border: 1px solid #999999 !important;
}

.search_white {
  width: 54% !important;
  margin-left: 3px;
  background-color: white !important;
  color: #6b7280 !important;
  height: 35px !important;
  padding: 0px 15px !important;
  border: none !important;
}

.search_white:focus {
  width: 54% !important;
  margin-left: 3px;
  background-color: white !important;
  color: #6b7280 !important;
  height: 35px !important;
  padding: 0px 15px !important;
  border: none !important;
}

.search_input {
  width: 60%;
}

.form-check-label {
  margin-top: 2px;
  margin-right: 15px;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

[type=checkbox], [type=radio] {
  color: #06b8ad !important;
  border: 1px solid #06b8ad;
}

.button_save {
  text-align: right;
}

.two-columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.three-columns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.column {
  flex-basis: 49%;
}

.input-group-text {
  border: none !important;
}

.two-column {
  flex-basis: 48%;
  margin-right: 5px;
}

.three-column {
  flex-basis: 33%;
}

.three-column-min {
  flex-basis: 20%;
  margin-right: 5px;
}

.three-column-max {
  flex-basis: 60%;
  margin-right: 5px;
}

.four-column {
  flex-basis: 25%;
}

.form-inline {
  width: 30% !important;
  display: inline !important;
}

.right-button {
  float: right;
  width: auto;
}

.large-input {
  width: 100% !important;
}

.add_button {
  text-align: center;
  background: #06b8ad;
  border-radius: 8px;
  color: white !important;
  padding: 15px 20px;
  margin-top: 10px;
}

.add_button:hover {
  background: #1ca198;
  border-radius: 8px;
  color: white !important;
  padding: 15px 20px;
  margin-top: 10px;
}

.form_comment {
  font-size: 15px;
  color: #999999;
  font-weight: 400;
  padding: 0px 0px 15px 0px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 0;
}

.button_large {
  width: 100%;
  text-align: center;
}

.button_grey {
  background-color: rgba(121, 131, 136, 0.6);
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.form_group {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 23px;
}

.hundreds-input-group input {
  border-left: none;
}

.input-group-pw input {
  border: 1px solid #999999;
}

.input-group-text {
  border: 1px solid #999999 !important;
  background-color: #f0f0f0 !important;
  padding: 10px 20px;
}

.label_description {
  font-size: 13px;
  font-style: italic;
}

.input-group-text img {
  width: 30px;
}

.group-rrss {
  background-color: transparent !important;
  padding: 10px !important;
}

.input-group-text {
  color: #999999;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  margin-bottom: 10px;
  padding: 8px 10px;
  background: white url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 140%;
  border-radius: 5px !important;
  color: #2B2B34;
  width: 100%;
  border: 1px solid #999999 !important;
  box-sizing: border-box;
  outline: 0;
  -webkit-appearance: none;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #06b8ad !important;
}

.select2-search__field {
  border: 1px solid #aaa !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: normal !important;
  color: black !important;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-top: 7px !important;
  padding-left: 5px !important;
}

.select2-container .select2-selection--single {
  height: 52px !important;
}

.hundreds-validation__input_error {
  border: 1px solid red !important;
}

.error_message {
  color: red;
}

.form-group_step {
  margin-bottom: 30px;
}

.form-group_step label {
  font-weight: 400 !important;
  margin-bottom: 0px !important;
}

.hundreds-form__checkbox input[type=radio], .hundreds-form__checkbox input[type=checkbox] {
  display: none;
}

.hundreds-form__checkbox_download {
  display: flex;
  flex-wrap: wrap;
}

.hundreds_checkbox {
  flex: 50%;
}

.hundreds-form__checkbox label {
  font-family: "Poppins", sans-serif;
  color: #2B2B34;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400 !important;
  line-height: 120%;
  vertical-align: middle;
  padding-left: 30px;
  margin-top: 15px;
  position: relative;
  text-align: left;
}

.hundreds-form__checkbox label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #06b8ad;
  background-color: transparent;
  border-radius: 10px;
}

.hundreds-form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 14px;
  width: 14px;
  background-color: white;
  border-radius: 10px;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

.hundreds-form__checkbox label a {
  color: #06b8ad;
  border-bottom: 1px solid #2B2B34;
}

.hundreds-form__checkbox input[type=radio]:checked + label, .hundreds-form__checkbox input[type=checkbox]:checked + label {
  color: #2B2B34;
}

.hundreds-form__checkbox input[type=radio]:checked + label:after, .hundreds-form__checkbox input[type=checkbox]:checked + label:after {
  background-color: #06b8ad;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.form-control, form input[type=email], form input[type=text], form input[type=number], form input[type=password], form select {
  padding: 15px;
  border-radius: 5px;
  height: 52px;
  color: #2B2B34;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  background: white;
  outline: 0;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -webkit-appearance: none;
  border: 1px solid #999999;
}

.table_btn img {
  width: 16px;
}

.btn-collapse, .btn-collapse:focus {
  width: 100%;
  text-align: left;
  background-color: #f0f0f0;
  color: black;
  border-color: none;
  position: relative;
}

.form_group span {
  font-size: 14px;
  color: #06b8ad;
}

.info {
  margin-left: 10px;
  border: 1px solid #06b8ad;
  border-radius: 18px;
  padding: 0px 4px;
  font-size: 9px !important;
  cursor: pointer;
}

form textarea {
  height: auto !important;
  resize: none !important;
}

select {
  height: 52px !important;
  padding: 17px;
  background: white url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 140%;
  border-radius: 5px;
  color: #2B2B34;
  width: 100%;
  border: none;
  box-sizing: border-box;
  outline: 0;
  -webkit-appearance: none;
}

.hundreds-input-group {
  position: relative;
}

.hundreds-input-group > img {
  position: absolute;
  top: 12px;
  left: 10px;
  width: 30px;
}

.hundreds-input-group > input {
  padding-left: 50px !important;
}

.rrss-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.rrss-column {
  flex: 0 0 49%;
}

.multiselect {
  width: 100%;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#mySelectOptions, #mySelectOptionsGenres, #mySelectOptionsFeaturing, #mySelectOptionsTrack, #mySelectOptionsFeaturingTrack {
  display: none;
  border: 1px solid #969696;
  background-color: #ffffff;
  max-height: 157px;
  overflow-y: scroll;
  padding: 10px 0px;
}

#mySelectOptions label, #mySelectOptionsGenres label, #mySelectOptionsFeaturing label, #mySelectOptionsTrack label, #mySelectOptionsFeaturingTrack label {
  display: block;
  font-weight: normal !important;
  display: block;
  font-size: 14px;
  white-space: nowrap;
  min-height: 1.2em;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 2.25rem 0 0.75rem;
}

#mySelectOptions label:hover, #mySelectOptionsGenres label:hover, #mySelectOptionsFeaturing label:hover, #mySelectOptionsTrack label:hover, #mySelectOptionsFeaturingTrack label:hover {
  background-color: #1e90ff;
}

#deselect_option {
  color: #06b8ad;
  text-decoration: underline;
  padding-bottom: 10px;
  cursor: pointer;
  font-weight: 400;
}

.custom-file {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  height: 52px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  padding: 15px 50px 15px 15px;
  border-radius: 5px;
  height: 52px !important;
  color: #000000;
  font-weight: 400 !important;
  font-size: 14px;
  background: white;
  outline: 0;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -webkit-appearance: none;
  border: 1px solid #969696;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.custom-file-label::after {
  height: 52px !important;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: 0;
  z-index: 3;
  display: block;
  line-height: 1.5;
  color: #ffffff;
  content: "Seleccionar";
  background-color: #06b8ad;
  border-left: 1px solid #999999;
  border-radius: 5px;
  padding: 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 14px;
}

.custom-file-label-no_button {
  border: none;
}

.custom-file-label-no_button::after {
  display: none;
}

.custom-file-label-border {
  border: 1px solid #999999;
}

form input[type=submit] {
  display: inline-block;
  position: relative;
  background: #06b8ad;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px;
  color: white;
  border: 1px solid transparent;
  opacity: 1;
  margin: 0;
  outline: 0;
  padding: 15px 25px;
  width: 170px;
  -webkit-appearance: none;
  -moz-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -o-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

form input[type=submit]:hover {
  background: #1ca198;
}

::-webkit-input-placeholder {
  opacity: 0.8;
}

:-moz-placeholder {
  opacity: 0.8;
}

::-moz-placeholder {
  opacity: 0.8;
}

:-ms-input-placeholder {
  opacity: 0.8;
}

@media only screen and (max-width: 1300px) {
  .rrss-column {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .two-columns {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  form input[type=email], form input[type=text], form input[type=number], form input[type=password], form textarea {
    padding: 10px;
  }
  select {
    padding: 12px;
  }
  form label {
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  form input[type=submit] {
    width: 100%;
  }
}
/* ------------------------------------------------------------------------- */
/* NEWSLETTER FORM STYLES */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* FOOTER STYLES */
/* ------------------------------------------------------------------------- */