/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE - Classes used throught all the project */


/* ------------------------------------------------------------------------- */

@media only screen and (max-width: 1200px){  }
@media only screen and (max-width: 1000px){  }
@media only screen and (max-width: 600px){  }
@media only screen and (max-width: 400px){  }