/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE FRO 100X100 */


/* ------------------------------------------------------------------------- */

.center-content
{
	text-align: center;
}

.left-content
{
	text-align: left;
}

.right-content
{
	text-align: right;
}
