/* ------------------------------------------------------------------------- */


/* 100X100 GRID SYSTEM */


/* ------------------------------------------------------------------------- */

.column-container
{
  font-size: 0;
}

.column{
  display: inline-block;
  padding: $grid-padding;
  vertical-align: top;
}

.column-1
{
  width: 8.33333333333%;
}
.column-2
{
  width: 16.6666666667%;
}
.column-3
{
  width: 25%;
}
.column-4
{
  width: 33.333333333%;
}
.column-5
{
  width: 41.666666667%;
}
.column-6
{
  width: 50%;
}
.column-7
{
  width: 58.333333333%;
}
.column-8
{
  width: 66.666666667%;
}
.column-9
{
  width: 75%;
}
.column-10
{
  width: 83.33333333%;
}
.column-11
{
  width: 91.66666667%;
}
.column-12
{
  width: 100%;
}

/* Special Grids */
.column-margin{
  display: inline-block;
  vertical-align: top;
}

.column-1_11
{
  width: 8.5%;
  margin-left: 0.29545455%;
  margin-right: 0.29545455%;
}


@media only screen and (max-width: 1100px){

.column-1-tablet
{
  width: 8.33333333333%;
}
.column-2-tablet
{
  width: 16.6666666667%;
}
.column-3-tablet
{
  width: 25%;
}
.column-4-tablet
{
  width: 33.333333333%;
}
.column-5-tablet
{
  width: 41.666666667%;
}
.column-6-tablet
{
  width: 50%;
}
.column-7-tablet
{
  width: 58.333333333%;
}
.column-8-tablet
{
  width: 66.666666667%;
}
.column-9-tablet
{
  width: 75%;
}
.column-10-tablet
{
  width: 83.33333333%;
}
.column-11-tablet
{
  width: 91.66666667%;
}
.column-12-tablet
{
  width: 100%;
}


}

@media only screen and (max-width: 800px){

.column-1-mobile
{
  width: 8.33333333333%;
}
.column-2-mobile
{
  width: 16.6666666667%;
}
.column-3-mobile
{
  width: 25%;
}
.column-4-mobile
{
  width: 33.333333333%;
}
.column-5-mobile
{
  width: 41.666666667%;
}
.column-6-mobile
{
  width: 50%;
}
.column-7-mobile
{
  width: 58.333333333%;
}
.column-8-mobile
{
  width: 66.666666667%;
}
.column-9-mobile
{
  width: 75%;
}
.column-10-mobile
{
  width: 83.33333333%;
}
.column-11-mobile
{
  width: 91.66666667%;
}
.column-12-mobile
{
  width: 100%;
}
.column-0-mobile
{
  display:none;
}

}