/* ------------------------------------------------------------------------- */


/* BASE PAGE STYLES */


/* ------------------------------------------------------------------------- */

.items-center {align-items: center;}
.bg-gray-100 {background-color: #f0f0f0 !important;}
.flex-col {flex-direction: column;}
.flex {display: flex;}
*, ::before, ::after {--tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);--tw-ring-offset-width: 0px;--tw-ring-offset-color: #fff;--tw-ring-color: rgba(59, 130, 246, 0.5);--tw-ring-offset-shadow: 0 0 #0000;--tw-ring-shadow: 0 0 #0000;}
*, ::before, ::after {--tw-shadow: 0 0 #0000;}
.shadow-md {--tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);}
.shadow-sm {--tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);}
.py-4 {padding-top: 1rem;padding-bottom: 1rem;}
.px-6 {padding-left: 1.5rem;padding-right: 1.5rem;}
.bg-white {--tw-bg-opacity: 1;background-color: rgba(255, 255, 255, var(--tw-bg-opacity));}
.overflow-hidden {overflow: hidden;}
.w-full {width: 100%;}
.mt-6 {margin-top: 1.5rem;}
.mt-4 {margin-top: 1rem;}
.text-gray-700 {--tw-text-opacity: 1;color: rgba(55, 65, 81, var(--tw-text-opacity));}
.block {display: block;}
.justify-end {justify-content: flex-end;}
.rounded {border-radius: 0.25rem;}
.min-h-screen {min-height: 100vh;}
.justify-between {justify-content: space-between;}
.h-16 {height: 4rem;}
.border-gray-100 {--tw-border-opacity: 1;border-color: rgba(243, 244, 246, var(--tw-border-opacity));}
.border-b {border-bottom-width: 1px;}
.bg-white {background-color: #fff!important;}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {display: block;}
.hidden {display: none;}
.navbar {display: block}
a:hover{color: $color!important;}
.divisor{color: $color;padding: 10px;}


@media (min-width: 992px) {
    .navbar-expand-lg {-webkit-box-orient: horizontal;-webkit-box-direction: normal;-ms-flex-flow: row nowrap;flex-flow: row nowrap;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: flex-start;}
}

@media (min-width: 768px) {
    .navbar {border-radius: 4px;}
    .navbar {position: relative;min-height: 50px;margin-bottom: 20px;border: 1px solid transparent;}
}

@media (min-width: 640px) {
    .sm\:rounded-lg {border-radius: 0.5rem;}
    .sm\:max-w-md {max-width: 28rem;}
    .sm\:pt-0 {padding-top: 0px;}
    .sm\:justify-center {justify-content: center;}
}
