/* ------------------------------------------------------------------------- */


/* VARIABLES */


/* ------------------------------------------------------------------------- */

$main: 'Poppins', sans-serif;  /* 400 y 600*/
$second: 'Lora', serif; /* 400 */


$color: #06b8ad;
$hover: #1ca198;
$color_yellow: #edbd45;
$color_blue: #272C51;
$color_orange: #EC5633;
$color-txt: #2B2B34;
$color-bt: #06b8ad;
$color-bg: #f0f0f0;
$color-dark_grey: #999999;
$color-input: white;


$border-radius: 5px;
$line-height: 140%;
$line-height2: 120%;
$line-height3: 160%;
$letter-spacing: 0px;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 600;
